import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { getallSummaryData } from "../../redux/propertiesDucks"
import {
  updateFields,
  formTasaciones_Tokko,
  send_form_mediacore,
} from "../../redux/contactDucks"
import toast, { Toaster } from "react-hot-toast"
import { graphql, useStaticQuery } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import useQuerySummary from "../../hooks/useQuerySummary"
const Form = ({ api_key, dispatch }) => {
  const type = useRef()
  const operation = useRef()
  const [datos, setDatos] = useState({
    nombre: "",
    email: "",
    phone: "",
    ubicacion: "",
    sup_cub: "",
    sup_tot: "",
    rooms: "",
    garage: "",
    age: "",
    amenities: [],
  })

  const {
    allSummaryData: allSummaryData,
    isLoading: isLoadingAllTokkoFilters,
  } = useQuerySummary()

  const [disabled, setDisabled] = useState(true)
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        env {
          CLIENTID
        }
        keys {
          captcha
          tokko
        }
      }
    }
  `)

  const handleInputChange = event => {
    if (event.target.name == "amenities") {
      if (datos.amenities.find(element => element === event.target.value)) {
        const newAmenities = datos.amenities.filter(
          element => element !== event.target.value
        )
        setDatos({
          ...datos,
          [event.target.name]: newAmenities,
        })
        return null
      }
      setDatos({
        ...datos,
        amenities: [...datos.amenities, event.target.value],
      })
      return null
    }
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    })
  }
  const clearForm = () => {
    setDatos({
      nombre: "",
      email: "",
      phone: "",
      ubicacion: "",
      sup_cub: "",
      sup_tot: "",
      rooms: "",
      garage: "",
      age: "",
      amenities: [],
    })
  }
  const sendForm = event => {
    event.preventDefault()
    if (realEstate.keys.tokko) {
      dispatch(
        formTasaciones_Tokko(
          type.current.value,
          operation.current.value,
          getUtm()
        )
      )
      // dispatch(
      //   send_form_mediacore(
      //     "services",
      //     {
      //       ...datos,
      //       type: type.current.value,
      //       operation: operation.current.value,
      //     },
      //     realEstate.env.CLIENTID
      //   )
      // )
    } else {
      dispatch(
        send_form_mediacore(
          "services",
          {
            ...datos,
            type: type.current.value,
            operation: operation.current.value,
          },
          realEstate.env.CLIENTID
        )
      )
    }
    window.scrollTo(0, 0)
    document.getElementById("formContact").reset()
    return toast.success("¡Mensaje enviado correctamente!")
  }
  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos))
    }
    update()
  }, [datos])
  useEffect(() => {
    if (!realEstate?.keys?.captcha) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
    if (api_key) {
      //   dispatch(getallSummaryData())
    }
  }, [api_key])

  const getUtm = (customs = []) => {
    if (typeof window !== "undefined") {
      var queryString = window.location.search
      var urlParams = new URLSearchParams(queryString)
      const tags = [customs]
      for (const [key, value] of urlParams) {
        if (key.includes("source")) {
          tags.push("Origen: " + value)
        }
        if (key.includes("medium")) {
          tags.push("Pieza: " + value)
        }
        if (key.includes("campaign")) {
          tags.push("Campaña: " + value)
        }
      }
      return tags
    }
  }
  return (
    <>
      <form
        onSubmit={sendForm}
        id="formContact"
        className="row content-form ajax-form"
      >
        <div className="col-lg-11">
          <div className="row">
            <label className="mb-4"> Datos de la propiedad</label>
            <div className="col-lg-6 pr-lg-3 pr-lg-2 mb-4 mb-lg-4">
              <input
                onChange={handleInputChange}
                type="text"
                required
                placeholder="Ubicación *"
                name="ubicacion"
                id="ubicacion"
              />
            </div>
            <div className="col-lg-6 pr-lg-3 pl-lg-2 mb-4 mb-lg-4 position-relative">
              <select required ref={operation} name="operation">
                <option value="">Operación</option>
                {allSummaryData &&
                allSummaryData.objects.operation_types.find(
                  element => element.operation_type === 1
                ) ? (
                  <option value="Venta">Venta</option>
                ) : (
                  ""
                )}
                {allSummaryData &&
                allSummaryData.objects.operation_types.find(
                  element => element.operation_type === 2
                ) ? (
                  <option value="Alquiler">Alquiler</option>
                ) : (
                  ""
                )}
                {allSummaryData &&
                allSummaryData.objects.operation_types.find(
                  element => element.operation_type === 3
                ) ? (
                  <option value="Alquiler Temporario">
                    Alquiler Temporario
                  </option>
                ) : (
                  ""
                )}
              </select>
              <i></i>
            </div>
            <div className="col-lg-6 pr-lg-3 mb-4 mb-lg-5 position-relative">
              <select ref={type} name="type">
                <option value="">Tipo de propiedad</option>
                {allSummaryData
                  ? allSummaryData.objects.property_types.map((item, index) => (
                      <option value={item.type}>{item.type}</option>
                    ))
                  : ""}
              </select>
              <i></i>
            </div>

            <div className="col-12 pr-3 camp">
              <div className="row">
                <div className="col-lg-6">
                  <label> Ambientes</label>
                  <div className="ticks d-flex justify-content-between align-items-center">
                    <span className="option numbers">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        id="amb-1"
                        name="rooms"
                        value="1"
                      />
                      <label for="amb-1" className="mb-0">
                        <span className="square customBorders">1</span>
                      </label>
                    </span>
                    <span className="option numbers">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        id="amb-2"
                        name="rooms"
                        value="2"
                      />
                      <label for="amb-2" className="mb-0">
                        <span className="square customBorders">2</span>
                      </label>
                    </span>
                    <span className="option numbers">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        id="amb-3"
                        name="rooms"
                        value="3"
                      />
                      <label for="amb-3" className="mb-0">
                        <span className="square customBorders">3</span>
                      </label>
                    </span>
                    <span className="option numbers">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        id="amb-4"
                        name="rooms"
                        value="4"
                      />
                      <label for="amb-4" className="mb-0">
                        <span className="square customBorders">4</span>
                      </label>
                    </span>
                    <span className="option numbers">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        id="amb-5"
                        name="rooms"
                        value="5"
                      />
                      <label for="amb-5" className="mb-0">
                        <span className="square customBorders">5</span>
                      </label>
                    </span>
                    <span className="option numbers">
                      <input
                        onChange={handleInputChange}
                        type="radio"
                        id="amb-6"
                        name="rooms"
                        value="6"
                      />
                      <label for="amb-6" className="mb-0">
                        <span className="square customBorders">+</span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 pr-3 py-3 py-lg-0 camp">
              <input
                onChange={handleInputChange}
                type="text"
                name="sup_cub"
                placeholder="Superficie Cubierta (m2)"
              />
            </div>
            <div className="col-6 pl-3 py-3 py-lg-0 camp">
              <input
                onChange={handleInputChange}
                type="text"
                name="sup_tot"
                placeholder="Superficie Total (m2)"
              />
            </div>
            <div className="col-lg-6 camp">
              <label>Antigüedad</label>
              <div className="ticks row d-flex justify-content-between align-items-center">
                <div className="col-6">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="radio"
                      id="age-1"
                      name="age"
                      value="A estrenar"
                    />
                    <label for="age-1" className="mb-0 me-0">
                      <span className=""></span>A estrenar
                    </label>
                  </span>
                </div>
                <div className="col-6">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="radio"
                      id="age-2"
                      name="age"
                      value="Hasta 10 años"
                    />
                    <label for="age-2" className="mb-0 me-0">
                      <span className=""></span>Hasta 10 años
                    </label>
                  </span>
                </div>
                <div className="col-6 mt-3">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="radio"
                      id="age-3"
                      name="age"
                      value="10 - 50 años"
                    />
                    <label for="age-3" className="mb-0 me-0">
                      <span className=""></span>10 - 50 años
                    </label>
                  </span>
                </div>
                <div className="col-6 mt-3">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="radio"
                      id="age-4"
                      name="age"
                      value="+50 años"
                    />
                    <label for="age-4" className="mb-0 me-0">
                      <span className=""></span>+50 años
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 camp d-none d-lg-block">
              <label>Garage</label>
              <div className="ticks d-flex justify-content-start align-items-center">
                <span className="option option-check me-5">
                  <input
                    onChange={handleInputChange}
                    type="radio"
                    id="garage-1"
                    name="garage"
                    value="Sí"
                  />
                  <label for="garage-1" className="mb-0">
                    <span className=""></span>Sí
                  </label>
                </span>
                <span className="option option-check me-5">
                  <input
                    onChange={handleInputChange}
                    type="radio"
                    id="garage-2"
                    name="garage"
                    value="No"
                  />
                  <label for="garage-2" className="mb-0">
                    <span className=""></span>No
                  </label>
                </span>
              </div>
            </div>
            <div className="col-12 camp mt-4 mt-lg-0">
              <label className="mb-4">Amenities</label>
              <div className="mt-2 ticks row align-items-start justify-content-between justify-content-lg-start">
                <div className="col-lg-3 col-6">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="checkbox"
                      id="SUM"
                      name="amenities"
                      value="SUM"
                    />
                    <label className="mb-3" for="SUM">
                      <span className="square"></span>SUM
                    </label>
                  </span>
                </div>
                <div className="col-lg-3 col-6">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="checkbox"
                      id="Laundry"
                      name="amenities"
                      value="Laundry"
                    />
                    <label className="mb-3" for="Laundry">
                      <span className="square"></span>Laundry
                    </label>
                  </span>
                </div>
                <div className="col-lg-3 col-6">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="checkbox"
                      id="Pileta"
                      name="amenities"
                      value="Pileta"
                    />
                    <label className="mb-3" for="Pileta">
                      <span className="square"></span>Pileta
                    </label>
                  </span>
                </div>
                <div className="col-lg-3 col-6">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="checkbox"
                      id="Gym"
                      name="amenities"
                      value="Gym"
                    />
                    <label className="mb-3" for="Gym">
                      <span className="square"></span>Gym
                    </label>
                  </span>
                </div>
                <div className="col-lg-3 col-6">
                  <span className="option option-check">
                    <input
                      onChange={handleInputChange}
                      type="checkbox"
                      id="Parrilla"
                      name="amenities"
                      value="Parrilla"
                    />
                    <label className="mb-3" for="Parrilla">
                      <span className="square"></span>Parrilla
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <label className="mb-4"> Datos de contacto</label>
            <div className="col-lg-6 camp mb-lg-4 py-lg-1 mb-2">
              <input
                onChange={handleInputChange}
                type="text"
                required
                name="nombre"
                placeholder="Nombre y Apellido *"
              />
            </div>
            <div className="col-lg-6 camp mb-lg-4 py-lg-1 mb-2">
              <input
                onChange={handleInputChange}
                type="text"
                required
                name="email"
                placeholder="E-Mail *"
              />
            </div>
            <div className="col-lg-6 camp mb-lg-4 py-lg-1 mb-2">
              <input
                onChange={handleInputChange}
                type="text"
                placeholder="Teléfono"
                name="phone"
                id="phone"
              />
            </div>
            <div className="col-lg-12 camp">
              <div
                className={
                  "d-lg-flex w-100 " +
                  (realEstate?.keys?.captcha &&
                    "justify-content-lg-between align-items-start")
                }
              >
                <div className={"d-lg-none d-block text-end mb-3 mt-lg-0"}>
                  *Campos obligatorios
                </div>
                {realEstate?.keys?.captcha ? (
                  <ReCAPTCHA
                    sitekey={
                      realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0
                    }
                    onChange={() => setDisabled(false)}
                  />
                ) : (
                  ""
                )}
                <div className={"d-none d-lg-block text-end mt-lg-0"}>
                  *Campos obligatorios
                </div>
              </div>
              <input
                type="submit"
                disabled={disabled}
                value="ENVIAR"
                className="btn btn-blue submit mt-4"
                id="submit-info"
              />
            </div>
          </div>
        </div>
      </form>
      <div id="alert-message"></div>
    </>
  )
}

export default connect(
  state => ({
    // allSummaryData: state.properties.allSummaryData,
    api_key: state.settings.keys.tokko,
  }),
  null
)(Form)
